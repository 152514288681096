import { default as React } from 'react'

export default function Logo({ fill, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={32} height={32} {...props}>
      <defs>
        <style/>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="smiling-face">
          <g id="smiling-face" data-name="smiling-face">
            <path fill={fill} d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2a8 8 0 100 16 8 8 0 000-16zm5 9a5 5 0 01-10 0z" id="🎨-Icon-Сolor"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
